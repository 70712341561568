<template>
    <div class="container main-area default-page">
        <div class="main-content">
            <h1 class="">
                <span>{{ page.title }}</span>
            </h1>
            <div class="page-content">
                <p v-html="page.content"></p>         
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['page']
}
</script>

<style scoped>

h1 {
    font-size: 35px;
    font-family: Raleway Regular;
    color: #9d0000;
}

</style>