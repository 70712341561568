<template>
    <page-header />
    <internalPageContent :page="page" :key="page.url" />
</template>

<script>
import PageHeader from "@/components/page_header/PageHeader.vue";
import InternalPageContent from "@/components/internalpage/InternalPageContent.vue";

export default {
    components: {
        PageHeader,
        InternalPageContent,
    },
    computed: {
        page() {
            return this.$store.getters["page/page"];
        },
    },

    created() {
        this.$store.dispatch(
            "page/getPage",
            this.$route.path.replace(/\//g, "").replace(".", "")
        );
    },
};
</script>
